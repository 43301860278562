import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import CookieConsent from "react-cookie-consent"
import Headermin from "../components/headermin"

export default class DatenschutzPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <CookieConsent
          location="bottom"
          buttonText="Verstanden!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "$orange" }}
          buttonStyle={{
            color: "$brown",
            fontFamily: "$font-family-sans-serif",
            fontSize: "13px",
          }}
          expires={150}
        >
          Diese Webseite verwendet nur technisch notwendige Cookies.{" "}
        </CookieConsent>
        <Headermin />

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 ">
                <h1 className="mt-0 py-4">Datenschutz</h1>
                {/* <hr className="divider my-4" /> */}
                <h2 id="m14">Einleitung</h2>
                <p>
                  Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
                  aufklären, welche Arten Ihrer personenbezogenen Daten
                  &#40;nachfolgend auch kurz als "Daten“ bezeichnet&#41; wir zu
                  welchen Zwecken und in welchem Umfang verarbeiten. Die
                  Datenschutzerklärung gilt für alle von uns durchgeführten
                  Verarbeitungen personenbezogener Daten, sowohl im Rahmen der
                  Erbringung unserer Leistungen als auch insbesondere auf
                  unseren Webseiten, in mobilen Applikationen sowie innerhalb
                  externer Onlinepräsenzen, wie z.B. unserer
                  Social-Media-Profile &#40;nachfolgend zusammenfassend
                  bezeichnet als "Onlineangebot“&#41;.
                </p>
                <p>
                  Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
                </p>
                <p>Stand: 25. Oktober 2022</p>
                <h2>Inhaltsübersicht</h2>{" "}
                <ul className="index">
                  <li>
                    <a className="index-link" href="#m14">
                      Einleitung
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m3">
                      Verantwortlicher
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#mOverview">
                      Übersicht der Verarbeitungen
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m13">
                      Maßgebliche Rechtsgrundlagen
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m27">
                      Sicherheitsmaßnahmen
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m25">
                      Übermittlung von personenbezogenen Daten
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m24">
                      Datenverarbeitung in Drittländern
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m134">
                      Einsatz von Cookies
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m317">
                      Geschäftliche Leistungen
                    </a>
                  </li>
                  <li>
                    <a className="index-link" href="#m42">
                      Begriffsdefinitionen
                    </a>
                  </li>
                </ul>
                <h2 id="m3">Verantwortlicher</h2>
                <p>
                  Zeltverleih Alois Markett GbR
                  <br />
                  Friedenstraße 8 b<br />
                  46485 Wesel
                </p>
                Vertretungsberechtigte Personen: <p>Alois Markett</p>
                E-Mail-Adresse: <p>info@markett-zelte.de</p>
                Telefon: <p>+49 &#40;0281&#41; 58 54</p>
                Impressum:{" "}
                <p>
                  <a
                    href="https://zeltverleih-markett.de/impressum/"
                    target="_blank"
                  >
                    https://zeltverleih-markett.de/impressum/
                  </a>
                </p>
                <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                <p>
                  Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                  Daten und die Zwecke ihrer Verarbeitung zusammen und verweist
                  auf die betroffenen Personen.
                </p>
                <h3>Arten der verarbeiteten Daten</h3>
                <ul>
                  <li>Bestandsdaten.</li>
                  <li>Zahlungsdaten.</li>
                  <li>Kontaktdaten.</li>
                  <li>Vertragsdaten.</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                  <li>Interessenten.</li>
                  <li>Geschäfts- und Vertragspartner.</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                  <li>
                    Erbringung vertraglicher Leistungen und Kundenservice.
                  </li>
                  <li>Kontaktanfragen und Kommunikation.</li>
                  <li>Büro- und Organisationsverfahren.</li>
                  <li>Verwaltung und Beantwortung von Anfragen.</li>
                </ul>
                <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                <p>
                  Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen
                  der DSGVO, auf deren Basis wir personenbezogene Daten
                  verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den
                  Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem
                  bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner
                  im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein,
                  teilen wir Ihnen diese in der Datenschutzerklärung mit.
                </p>
                <ul>
                  <li>
                    <strong>
                      Vertragserfüllung und vorvertragliche Anfragen &#40;Art. 6
                      Abs. 1 S. 1 lit. b&#41; DSGVO&#41;
                    </strong>{" "}
                    - Die Verarbeitung ist für die Erfüllung eines Vertrags,
                    dessen Vertragspartei die betroffene Person ist, oder zur
                    Durchführung vorvertraglicher Maßnahmen erforderlich, die
                    auf Anfrage der betroffenen Person erfolgen.
                  </li>
                  <li>
                    <strong>
                      Rechtliche Verpflichtung &#40;Art. 6 Abs. 1 S. 1 lit.
                      c&#41; DSGVO&#41;
                    </strong>{" "}
                    - Die Verarbeitung ist zur Erfüllung einer rechtlichen
                    Verpflichtung erforderlich, der der Verantwortliche
                    unterliegt.
                  </li>
                  <li>
                    <strong>
                      Berechtigte Interessen &#40;Art. 6 Abs. 1 S. 1 lit. f&#41;
                      DSGVO&#41;
                    </strong>{" "}
                    - Die Verarbeitung ist zur Wahrung der berechtigten
                    Interessen des Verantwortlichen oder eines Dritten
                    erforderlich, sofern nicht die Interessen oder Grundrechte
                    und Grundfreiheiten der betroffenen Person, die den Schutz
                    personenbezogener Daten erfordern, überwiegen.
                  </li>
                </ul>
                <p>
                  Zusätzlich zu den Datenschutzregelungen der
                  Datenschutz-Grundverordnung gelten nationale Regelungen zum
                  Datenschutz in Deutschland. Hierzu gehört insbesondere das
                  Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei
                  der Datenverarbeitung &#40;Bundesdatenschutzgesetz –
                  BDSG&#41;. Das BDSG enthält insbesondere Spezialregelungen zum
                  Recht auf Auskunft, zum Recht auf Löschung, zum
                  Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
                  personenbezogener Daten, zur Verarbeitung für andere Zwecke
                  und zur Übermittlung sowie automatisierten
                  Entscheidungsfindung im Einzelfall einschließlich Profiling.
                  Des Weiteren regelt es die Datenverarbeitung für Zwecke des
                  Beschäftigungsverhältnisses &#40;§ 26 BDSG&#41;, insbesondere
                  im Hinblick auf die Begründung, Durchführung oder Beendigung
                  von Beschäftigungsverhältnissen sowie die Einwilligung von
                  Beschäftigten. Ferner können Landesdatenschutzgesetze der
                  einzelnen Bundesländer zur Anwendung gelangen.
                </p>
                <h2 id="m27">Sicherheitsmaßnahmen</h2>
                <p>
                  Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                  Berücksichtigung des Stands der Technik, der
                  Implementierungskosten und der Art, des Umfangs, der Umstände
                  und der Zwecke der Verarbeitung sowie der unterschiedlichen
                  Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung
                  der Rechte und Freiheiten natürlicher Personen geeignete
                  technische und organisatorische Maßnahmen, um ein dem Risiko
                  angemessenes Schutzniveau zu gewährleisten.
                </p>
                <p>
                  Zu den Maßnahmen gehören insbesondere die Sicherung der
                  Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
                  Kontrolle des physischen und elektronischen Zugangs zu den
                  Daten als auch des sie betreffenden Zugriffs, der Eingabe, der
                  Weitergabe, der Sicherung der Verfügbarkeit und ihrer
                  Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
                  eine Wahrnehmung von Betroffenenrechten, die Löschung von
                  Daten und Reaktionen auf die Gefährdung der Daten
                  gewährleisten. Ferner berücksichtigen wir den Schutz
                  personenbezogener Daten bereits bei der Entwicklung bzw.
                  Auswahl von Hardware, Software sowie Verfahren entsprechend
                  dem Prinzip des Datenschutzes, durch Technikgestaltung und
                  durch datenschutzfreundliche Voreinstellungen.
                </p>
                <p>
                  TLS-Verschlüsselung &#40;https&#41;: Um Ihre via unserem
                  Online-Angebot übermittelten Daten zu schützen, nutzen wir
                  eine TLS-Verschlüsselung. Sie erkennen derart verschlüsselte
                  Verbindungen an dem Präfix https:// in der Adresszeile Ihres
                  Browsers.
                </p>
                <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
                <p>
                  Im Rahmen unserer Verarbeitung von personenbezogenen Daten
                  kommt es vor, dass die Daten an andere Stellen, Unternehmen,
                  rechtlich selbstständige Organisationseinheiten oder Personen
                  übermittelt oder sie ihnen gegenüber offengelegt werden. Zu
                  den Empfängern dieser Daten können z.B. mit IT-Aufgaben
                  beauftragte Dienstleister oder Anbieter von Diensten und
                  Inhalten, die in eine Webseite eingebunden werden, gehören. In
                  solchen Fall beachten wir die gesetzlichen Vorgaben und
                  schließen insbesondere entsprechende Verträge bzw.
                  Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den
                  Empfängern Ihrer Daten ab.
                </p>
                <h2 id="m24">Datenverarbeitung in Drittländern</h2>
                <p>
                  Sofern wir Daten in einem Drittland &#40;d.h., außerhalb der
                  Europäischen Union &#40;EU&#41;, des Europäischen
                  Wirtschaftsraums &#40;EWR&#41;&#41; verarbeiten oder die
                  Verarbeitung im Rahmen der Inanspruchnahme von Diensten
                  Dritter oder der Offenlegung bzw. Übermittlung von Daten an
                  andere Personen, Stellen oder Unternehmen stattfindet, erfolgt
                  dies nur im Einklang mit den gesetzlichen Vorgaben.{" "}
                </p>
                <p>
                  Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich
                  oder gesetzlich erforderlicher Übermittlung verarbeiten oder
                  lassen wir die Daten nur in Drittländern mit einem anerkannten
                  Datenschutzniveau, vertraglichen Verpflichtung durch
                  sogenannte Standardschutzklauseln der EU-Kommission, beim
                  Vorliegen von Zertifizierungen oder verbindlicher internen
                  Datenschutzvorschriften verarbeiten &#40;Art. 44 bis 49 DSGVO,
                  Informationsseite der EU-Kommission:{" "}
                  <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                    target="_blank"
                  >
                    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
                  </a>
                  &#41;.
                </p>
                <h2 id="m134">Einsatz von Cookies</h2>
                <p>
                  Cookies sind kleine Textdateien, bzw. sonstige
                  Speichervermerke, die Informationen auf Endgeräten speichern
                  und Informationen aus den Endgeräten auslesen. Z.B. um den
                  Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in
                  einem E-Shop, die aufgerufenen Inhalte oder verwendete
                  Funktionen eines Onlineangebotes speichern. Cookies können
                  ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu
                  Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von
                  Onlineangeboten sowie der Erstellung von Analysen der
                  Besucherströme.{" "}
                </p>
                <p>
                  <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies
                  im Einklang mit den gesetzlichen Vorschriften ein. Daher holen
                  wir von den Nutzern eine vorhergehende Einwilligung ein, außer
                  wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung
                  ist insbesondere nicht notwendig, wenn das Speichern und das
                  Auslesen der Informationen, also auch von Cookies, unbedingt
                  erforderlich sind, um dem den Nutzern einen von ihnen
                  ausdrücklich gewünschten Telemediendienst &#40;also unser
                  Onlineangebot&#41; zur Verfügung zu stellen. Die widerrufliche
                  Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
                  und enthält die Informationen zu der jeweiligen
                  Cookie-Nutzung.
                </p>
                <p>
                  <strong>
                    Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:{" "}
                  </strong>
                  Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die
                  personenbezogenen Daten der Nutzer mit Hilfe von Cookies
                  verarbeiten, hängt davon ab, ob wir Nutzer um eine
                  Einwilligung bitten. Falls die Nutzer einwilligen, ist die
                  Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
                  Einwilligung. Andernfalls werden die mithilfe von Cookies
                  verarbeiteten Daten auf Grundlage unserer berechtigten
                  Interessen &#40;z.B. an einem betriebswirtschaftlichen Betrieb
                  unseres Onlineangebotes und Verbesserung seiner
                  Nutzbarkeit&#41; verarbeitet oder, wenn dies im Rahmen der
                  Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der
                  Einsatz von Cookies erforderlich ist, um unsere vertraglichen
                  Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies
                  von uns verarbeitet werden, darüber klären wir im Laufe dieser
                  Datenschutzerklärung oder im Rahmen von unseren Einwilligungs-
                  und Verarbeitungsprozessen auf.
                </p>
                <p>
                  <strong>Speicherdauer: </strong>Im Hinblick auf die
                  Speicherdauer werden die folgenden Arten von Cookies
                  unterschieden:
                </p>
                <ul>
                  <li>
                    <strong>
                      Temporäre Cookies &#40;auch: Session- oder
                      Sitzungs-Cookies&#41;:
                    </strong>{" "}
                    Temporäre Cookies werden spätestens gelöscht, nachdem ein
                    Nutzer ein Online-Angebot verlassen und sein Endgerät
                    &#40;z.B. Browser oder mobile Applikation&#41; geschlossen
                    hat.
                  </li>
                  <li>
                    <strong>Permanente Cookies:</strong> Permanente Cookies
                    bleiben auch nach dem Schließen des Endgerätes gespeichert.
                    So können beispielsweise der Login-Status gespeichert oder
                    bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer
                    eine Website erneut besucht. Ebenso können die mit Hilfe von
                    Cookies erhobenen Daten der Nutzer zur Reichweitenmessung
                    verwendet werden. Sofern wir Nutzern keine expliziten
                    Angaben zur Art und Speicherdauer von Cookies mitteilen
                    &#40;z. B. im Rahmen der Einholung der Einwilligung&#41;,
                    sollten Nutzer davon ausgehen, dass Cookies permanent sind
                    und die Speicherdauer bis zu zwei Jahre betragen kann.
                  </li>
                </ul>
                <p>
                  <strong>
                    Allgemeine Hinweise zum Widerruf und Widerspruch
                    &#40;Opt-Out&#41;:{" "}
                  </strong>
                  Nutzer können die von ihnen abgegebenen Einwilligungen
                  jederzeit widerrufen und zudem einen Widerspruch gegen die
                  Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21
                  DSGVO einlegen. Nutzer können ihren Widerspruch auch über die
                  Einstellungen ihres Browsers erklären, z.B. durch
                  Deaktivierung der Verwendung von Cookies &#40;wobei dadurch
                  auch die Funktionalität unserer Online-Dienste eingeschränkt
                  sein kann&#41;. Ein Widerspruch gegen die Verwendung von
                  Cookies zu Online-Marketing-Zwecken kann auch über die
                  Websites{" "}
                  <a href="https://optout.aboutads.info" target="_blank">
                    https://optout.aboutads.info
                  </a>{" "}
                  und{" "}
                  <a href="https://www.youronlinechoices.com/" target="_blank">
                    https://www.youronlinechoices.com/
                  </a>{" "}
                  erklärt werden.
                </p>
                <p>
                  <strong>
                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                    Diensten:
                  </strong>
                </p>
                <ul className="m-elements">
                  <li>
                    <strong>
                      Verarbeitung von Cookie-Daten auf Grundlage einer
                      Einwilligung:{" "}
                    </strong>
                    Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management
                    ein, in dessen Rahmen die Einwilligungen der Nutzer in den
                    Einsatz von Cookies, bzw. der im Rahmen des
                    Cookie-Einwilligungs-Management-Verfahrens genannten
                    Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
                    verwaltet und widerrufen werden können. Hierbei wird die
                    Einwilligungserklärung gespeichert, um deren Abfrage nicht
                    erneut wiederholen zu müssen und die Einwilligung
                    entsprechend der gesetzlichen Verpflichtung nachweisen zu
                    können. Die Speicherung kann serverseitig und/oder in einem
                    Cookie &#40;sogenanntes Opt-In-Cookie, bzw. mithilfe
                    vergleichbarer Technologien&#41; erfolgen, um die
                    Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu
                    können. Vorbehaltlich individueller Angaben zu den Anbietern
                    von Cookie-Management-Diensten, gelten die folgenden
                    Hinweise: Die Dauer der Speicherung der Einwilligung kann
                    bis zu zwei Jahren betragen. Hierbei wird ein pseudonymer
                    Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
                    Einwilligung, Angaben zur Reichweite der Einwilligung
                    &#40;z. B. welche Kategorien von Cookies und/oder
                    Diensteanbieter&#41; sowie dem Browser, System und
                    verwendeten Endgerät gespeichert.
                  </li>
                </ul>
                <h2 id="m317">Geschäftliche Leistungen</h2>
                <p>
                  Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner,
                  z.B. Kunden und Interessenten &#40;zusammenfassend bezeichnet
                  als "Vertragspartner"&#41; im Rahmen von vertraglichen und
                  vergleichbaren Rechtsverhältnissen sowie damit verbundenen
                  Maßnahmen und im Rahmen der Kommunikation mit den
                  Vertragspartnern &#40;oder vorvertraglich&#41;, z.B., um
                  Anfragen zu beantworten.
                </p>
                <p>
                  Wir verarbeiten diese Daten, um unsere vertraglichen
                  Verpflichtungen zu erfüllen. Dazu gehören insbesondere die
                  Verpflichtungen zur Erbringung der vereinbarten Leistungen,
                  etwaige Aktualisierungspflichten und Abhilfe bei
                  Gewährleistungs- und sonstigen Leistungsstörungen. Darüber
                  hinaus verarbeiten wir die Daten zur Wahrung unserer Rechte
                  und zum Zwecke der mit diesen Pflichten verbundenen
                  Verwaltungsaufgaben sowie der Unternehmensorganisation.
                  Darüber hinaus verarbeiten wir die Daten auf Grundlage unserer
                  berechtigten Interessen an einer ordnungsgemäßen und
                  betriebswirtschaftlichen Geschäftsführung sowie an
                  Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und
                  unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer
                  Daten, Geheimnisse, Informationen und Rechte &#40;z.B. zur
                  Beteiligung von Telekommunikations-, Transport- und sonstigen
                  Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und
                  Rechtsberatern, Zahlungsdienstleistern oder
                  Finanzbehörden&#41;. Im Rahmen des geltenden Rechts geben wir
                  die Daten von Vertragspartnern nur insoweit an Dritte weiter,
                  als dies für die vorgenannten Zwecke oder zur Erfüllung
                  gesetzlicher Pflichten erforderlich ist. Über weitere Formen
                  der Verarbeitung, z.B. zu Marketingzwecken, werden die
                  Vertragspartner im Rahmen dieser Datenschutzerklärung
                  informiert.
                </p>
                <p>
                  Welche Daten für die vorgenannten Zwecke erforderlich sind,
                  teilen wir den Vertragspartnern vor oder im Rahmen der
                  Datenerhebung, z.B. in Onlineformularen, durch besondere
                  Kennzeichnung &#40;z.B. Farben&#41; bzw. Symbole &#40;z.B.
                  Sternchen o.ä.&#41;, oder persönlich mit.
                </p>
                <p>
                  Wir löschen die Daten nach Ablauf gesetzlicher
                  Gewährleistungs- und vergleichbarer Pflichten, d.h.,
                  grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die
                  Daten in einem Kundenkonto gespeichert werden, z.B., solange
                  sie aus gesetzlichen Gründen der Archivierung aufbewahrt
                  werden müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei
                  steuerrechtlich relevanten Unterlagen sowie bei
                  Handelsbüchern, Inventaren, Eröffnungsbilanzen,
                  Jahresabschlüssen, die zum Verständnis dieser Unterlagen
                  erforderlichen Arbeitsanweisungen und sonstigen
                  Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie
                  bei empfangenen Handels- und Geschäftsbriefen und Wiedergaben
                  der abgesandten Handels- und Geschäftsbriefe sechs Jahre. Die
                  Frist beginnt mit Ablauf des Kalenderjahres, in dem die letzte
                  Eintragung in das Buch gemacht, das Inventar, die
                  Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht
                  aufgestellt, der Handels- oder Geschäftsbrief empfangen oder
                  abgesandt worden oder der Buchungsbeleg entstanden ist, ferner
                  die Aufzeichnung vorgenommen worden ist oder die sonstigen
                  Unterlagen entstanden sind.
                </p>
                <p>
                  Soweit wir zur Erbringung unserer Leistungen Drittanbieter
                  oder Plattformen einsetzen, gelten im Verhältnis zwischen den
                  Nutzern und den Anbietern die Geschäftsbedingungen und
                  Datenschutzhinweise der jeweiligen Drittanbieter oder
                  Plattformen.{" "}
                </p>
                <ul className="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten
                    &#40;z.B. Namen, Adressen&#41;; Zahlungsdaten &#40;z.B.
                    Bankverbindungen, Rechnungen, Zahlungshistorie&#41;;
                    Kontaktdaten &#40;z.B. E-Mail, Telefonnummern&#41;;
                    Vertragsdaten &#40;z.B. Vertragsgegenstand, Laufzeit,
                    Kundenkategorie&#41;.
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Interessenten;
                    Geschäfts- und Vertragspartner.
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Erbringung
                    vertraglicher Leistungen und Kundenservice; Kontaktanfragen
                    und Kommunikation; Büro- und Organisationsverfahren;
                    Verwaltung und Beantwortung von Anfragen.
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                    vorvertragliche Anfragen &#40;Art. 6 Abs. 1 S. 1 lit. b&#41;
                    DSGVO&#41;; Rechtliche Verpflichtung &#40;Art. 6 Abs. 1 S. 1
                    lit. c&#41; DSGVO&#41;; Berechtigte Interessen &#40;Art. 6
                    Abs. 1 S. 1 lit. f&#41; DSGVO&#41;.
                  </li>
                </ul>
                <p>
                  <strong>
                    Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                    Diensten:
                  </strong>
                </p>
                <ul className="m-elements">
                  <li>
                    <strong>Handwerkliche Leistungen: </strong>Wir verarbeiten
                    die Daten unserer Kunden sowie Auftraggeber &#40;nachfolgend
                    einheitlich als "Kunden" bezeichnet&#41;, um ihnen die
                    Auswahl, den Erwerb bzw. die Beauftragung der gewählten
                    Leistungen oder Werke sowie verbundener Tätigkeiten als auch
                    deren Bezahlung und Zustellung bzw. Ausführung oder
                    Erbringung zu ermöglichen. Die erforderlichen Angaben sind
                    als solche im Rahmen des Auftrags-, Bestell- bzw.
                    vergleichbaren Vertragsschlusses gekennzeichnet und umfassen
                    die zur Auslieferung und Abrechnung benötigten Angaben sowie
                    Kontaktinformationen, um etwaige Rücksprachen halten zu
                    können; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung
                    und vorvertragliche Anfragen &#40;Art. 6 Abs. 1 S. 1 lit.
                    b&#41; DSGVO&#41;.
                  </li>
                </ul>
                <h2 id="m42">Begriffsdefinitionen</h2>
                <p>
                  In diesem Abschnitt erhalten Sie eine Übersicht über die in
                  dieser Datenschutzerklärung verwendeten Begrifflichkeiten.
                  Viele der Begriffe sind dem Gesetz entnommen und vor allem im
                  Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind
                  verbindlich. Die nachfolgenden Erläuterungen sollen dagegen
                  vor allem dem Verständnis dienen. Die Begriffe sind
                  alphabetisch sortiert.
                </p>
                <ul className="glossary">
                  <li>
                    <strong>Personenbezogene Daten:</strong> "Personenbezogene
                    Daten“ sind alle Informationen, die sich auf eine
                    identifizierte oder identifizierbare natürliche Person
                    &#40;im Folgenden "betroffene Person“&#41; beziehen; als
                    identifizierbar wird eine natürliche Person angesehen, die
                    direkt oder indirekt, insbesondere mittels Zuordnung zu
                    einer Kennung wie einem Namen, zu einer Kennnummer, zu
                    Standortdaten, zu einer Online-Kennung &#40;z.B. Cookie&#41;
                    oder zu einem oder mehreren besonderen Merkmalen
                    identifiziert werden kann, die Ausdruck der physischen,
                    physiologischen, genetischen, psychischen, wirtschaftlichen,
                    kulturellen oder sozialen Identität dieser natürlichen
                    Person sind.{" "}
                  </li>
                  <li>
                    <strong>Verantwortlicher:</strong> Als "Verantwortlicher“
                    wird die natürliche oder juristische Person, Behörde,
                    Einrichtung oder andere Stelle, die allein oder gemeinsam
                    mit anderen über die Zwecke und Mittel der Verarbeitung von
                    personenbezogenen Daten entscheidet, bezeichnet.{" "}
                  </li>
                  <li>
                    <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit
                    oder ohne Hilfe automatisierter Verfahren ausgeführte
                    Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                    personenbezogenen Daten. Der Begriff reicht weit und umfasst
                    praktisch jeden Umgang mit Daten, sei es das Erheben, das
                    Auswerten, das Speichern, das Übermitteln oder das Löschen.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
